import React, { useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import logo from "../Images/logo_crop.png";

export const Navigation = (props) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const scrollToSection = (sectionId) => {
    scroll.scrollTo(sectionId, {
      duration: 500,
      smooth: true,
    });
    handleNavCollapse(); 
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{ height: "100hv" }}>
        <div className="navbar-header">
        <a href="#page-top" onClick={() => scrollToSection("page-top")}>
            <img src={logo} alt="logo" width="18%" />
          </a>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={handleNavCollapse}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>
        <div
          className={`collapse navbar-collapse ${
            isNavCollapsed ? "" : "show"
          }`}
          id="bs-example-navbar-collapse-1"
          style={{ marginTop: "2%" }}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#header"
                className="page-scroll"
                onClick={() => scrollToSection("header")}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#features"
                className="page-scroll"
                onClick={() => scrollToSection("features")}
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="#advantages"
                className="page-scroll"
                onClick={() => scrollToSection("advantages")}
              >
                Advantages
              </a>
            </li>

            <li>
              <a
                href="#faq"
                className="page-scroll"
                onClick={() => scrollToSection("faq")}
              >
                FAQ
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="page-scroll"
                onClick={() => scrollToSection("services")}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
};
